
ChatraID = 'gQbyoQF6QkRozgkYS';
(function (d, w, c) {
    w.addEventListener("load", function () {
        var n = d.getElementsByTagName('script')[0],
            s = d.createElement('script');
        w[c] = w[c] || function () {
            (w[c].q = w[c].q || []).push(arguments);
        };
        s.async = true;
        s.src = (d.location.protocol === 'https:' ? 'https:' : 'http:')
            + '//call.chatra.io/chatra.js';
        n.parentNode.insertBefore(s, n);
    });
})(document, window, 'Chatra');

function chatraWidget() {
    window.ChatraSetup = {
        deferredLoading: true,
        mode: 'widget',
    };
}

function chatraFrame() {
    window.ChatraSetup = {
        deferredLoading: true,

        mode: 'frame',
        /* id of the block you want to embed chat into */
        injectTo: 'chatra-wrapper'
    };
}

let states = [
    {
        func: chatraFrame,
        string: "#chat-with-us"
    },
    {
        func: function (w) {
            chatraWidget();
            w.Chatra('minimizeWidget');
        },
        string: "#close"
    }
];


function chatraFrameCheck(hash) {
    states.forEach(s => {
        if (hash.startsWith(s.string)) {
            try {
                s.func(this);
                this.Chatra('restart');
            } catch (e) {
                if (e instanceof TypeError) {
                    // Chatra not ready
                } else {
                    throw e;
                }
            }
        }
    });
}

let stateIndex = 0;
function chatraStateFrameCheck(hash) {
    if (hash.startsWith(states[stateIndex].string)) {
        try {
            stateCheck[states].func(this);
            this.Chatra('restart');
        } catch (e) {
            if (e instanceof TypeError) {
                // Chatra not ready
            } else {
                throw e;
            }
        } finally {
            stateIndex = stateIndex >= states.length - 1 ? 0 : stateIndex + 1;
        }
    }
}

chatraWidget();

window.addEventListener("hashchange", function (e) { chatraFrameCheck(e.target.location.hash); }, false);
chatraFrameCheck(window.location.hash);